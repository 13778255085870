import { settingsSelectors, State, TabDisplayInterface, UserRole, UserType } from '@wam/shared';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class AccountsTabDisplayService implements TabDisplayInterface {
  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(settingsSelectors.getUserType)),
      this.store.pipe(select(authenticationSelectors.getUserRoles)),
    ]).pipe(
      map(
        ([userType, userRoles]) =>
          userType === UserType.ADMIN &&
          (userRoles?.includes(UserRole.INTERNAL_ADMIN) ||
            userRoles?.includes(UserRole.ACCOUNT_ADMIN) ||
            userRoles?.includes(UserRole.SUPPORT_ENGINEER)),
      ),
    );
  }
}
