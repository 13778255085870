import { settingsSelectors, State, TabDisplayInterface, UserRole, UserType } from '@wam/shared';
import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class AssessmentDesignerTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    const isFirst = false;

    return this.shouldShow().pipe(
      map((shouldShow: boolean) => shouldShow && isFirst),
      takeUntil(this.destroyed$),
    );
  }

  shouldShow(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(settingsSelectors.getUserType)),
      this.store.pipe(select(authenticationSelectors.getUserRoles)),
    ]).pipe(
      map(
        ([userType, userRoles]) =>
          userType === UserType.ADMIN &&
          (userRoles?.includes(UserRole.INTERNAL_ADMIN) ||
            userRoles?.includes(UserRole.CURRICULUM_ADMIN)),
      ),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
