import { settingsSelectors, State, TabDisplayInterface, UserRole, UserType } from '@wam/shared';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class NotificationsManagerTabDisplayService implements TabDisplayInterface {
  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    const isAdmin$ = this.store.pipe(
      select(settingsSelectors.getUserType),
      map((userType) => userType === UserType.ADMIN),
    );

    const isProductOrInternalAdmin$ = this.store.pipe(
      select(authenticationSelectors.getUserRoles),
      map(
        (userRoles) =>
          userRoles?.includes(UserRole.INTERNAL_ADMIN) ||
          userRoles?.includes(UserRole.PRODUCT_ADMIN),
      ),
    );

    return combineLatest([isAdmin$, isProductOrInternalAdmin$]).pipe(
      map(([isAdmin, isProductOrInternalAdmin]) => isAdmin && isProductOrInternalAdmin),
    );
  }
}
