import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import { configurationSelectors, settingsSelectors, State, TabDisplayInterface } from '@wam/shared';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isInternalAdminOrAccountAdminOrSupportEngineer } from '../authentication/user.data';

@Injectable({
  providedIn: 'root',
})
export class FamiliesTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(settingsSelectors.getUserType)),
      this.store.pipe(select(authenticationSelectors.getUserRoles)),
      this.store.pipe(select(configurationSelectors.isEnabled('FAMILIES_TAB'))),
    ]).pipe(
      map(
        ([userType, userRoles, familiesTabFeatureToggle]) =>
          familiesTabFeatureToggle &&
          isInternalAdminOrAccountAdminOrSupportEngineer(userType, userRoles),
      ),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
