import { State, TabDisplayInterface, UserRole } from '@wam/shared';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class DesignerTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    const isFirst = false;

    return this.shouldShow().pipe(map((shouldShow: boolean) => shouldShow && isFirst));
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(select(authenticationSelectors.getUserRoles)).pipe(
      map(
        (userRoles) =>
          userRoles?.includes(UserRole.INTERNAL_ADMIN) ||
          userRoles?.includes(UserRole.CURRICULUM_ADMIN),
      ),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
